@import '../../assets/scss/Mixins.scss';

.dropdown {
    @include flex(column, flex-start, 0px, flex-start);

    .options {
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 56px;
        @include flex(column, center, 8px, center);

        .iconOpen {
            transform: rotate(0deg);
            animation: iconOpen ease .2s;
        }

        .iconClose {
            transform: rotate(360deg);
            animation: iconClose ease .2s;
        }
    }

    .close {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-10px);
        animation: close ease .2s;
    }

    .open {
        visibility: visible;
        transform: translateY(64px);
        opacity: 1;
        animation: open ease .2s;
    }
}

@keyframes open {
    from {
        transform: translateY(-10px);
        opacity: 0;
    }

    to {
        transform: translateY(64px);
        opacity: 1;
    }
}

@keyframes close {
    from {
        transform: translateY(64px);
        opacity: 1;
    }

    to {
        transform: translateY(-10px);
        opacity: 0;
    }
}

@keyframes iconOpen {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes iconClose {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@media screen and (max-width: 540px) {
    .dropdown .options {
        width: 40px;
    }
}