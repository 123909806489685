@import '../../../../assets/scss/Mixins.scss';

.sidebar {
    z-index: 4;
    height: calc(100vh - 88px);
    margin-top: 88px;
    position: fixed;
    left: 0;
    width: 360px;
    min-width: 306px;
    flex-shrink: 0;
    @include flex(column, flex-start, 16px, flex-start);
    transform: translateX(-100%);
    opacity: 0;
    background-color: var(--block-color);

    .header_profile {
        border-bottom: 1px solid #1A1E23;
        @include padding-width(16px, 100%);
        @include flex(row, center, 8px, flex-start);

        .logo {
            @include circle();
            @include box(56px);
        }

        .profile {
            background-color: var(--component-color);
            border: 0.5px solid var(--switcher-theme-border);
            padding-right: 16px;
            flex: 1 0 0;
            height: 56px;
            border-radius: 100px;
            width: 184px;
            @include flex(row, center, 8px, flex-start);

            .avatar_container {
                width: 56px;
                height: 56px;
                
                .avatar {
                    @include circle();
                    @include box(56px);
                }
            }

            .username {
                @include text-hidden();
                @include text(24, 700, var(--text-color))
            }
        }
    }

    .menu {
        overflow-y: scroll;
        position: fixed;
        width: 100%;
        height: calc(100% - 88px - 16px);
        bottom: 0;
        @include flex(row, flex-start, 8px, flex-start);

        .guilds {
            z-index: 1;
            padding-top: 8px;
            @include flex(column, flex-start, 24px, flex-start);

            .guild {
                height: calc(100% - 88px - 16px);
                @include flex(row, center, 16px, flex-start);
                cursor: pointer;

                .decor {
                    width: 8px;
                    height: 56px;
                    border-radius: 0px 8px 8px 0px;
                    background-color: var(--primary-color);
                    animation: decor .2s ease-in-out;
                }

                @keyframes decor {
                    0% {
                        height: 28px;
                    }

                    100% {
                        height: 56px;
                    }
                }

                .no {
                    width: 8px;
                    height: 100%;
                    opacity: 0;
                    border-radius: 0px 8px 8px 0px;
                    background-color: var(--primary-color);
                    width: 8px;
                    height: 0;
                    transition: all var(--animation-time);
                }

                .nodecor {
                    width: 8px;
                    height: 100%;
                    opacity: 0;
                    border-radius: 0px 8px 8px 0px;
                    background-color: var(--primary-color);
                    width: 8px;
                    height: 0;
                    transition: all var(--animation-time);
                }

                .tooltype {
                    display: none;
                    background-color: var(--primary-color);
                    border-radius: 32px;
                    padding: 8px 24px;
                    align-items: center;
                    gap: 8px;
                    max-width: 300px;
                    animation: ease opactiy var(--animation-time);
                    transition: var(--animation-time);

                    .name {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        align-self: stretch;
                        overflow: hidden;
                        text-align: center;
                        text-overflow: ellipsis;
                        @include text(17, 800, #ffffff)
                    }
                }

                .icon {
                    border-radius: 100%;
                    @include box(56px);
                    transition: all var(--animation-time);
                }

                .active {
                    border-radius: 20px;
                    @include box(56px);
                    transition: all var(--animation-time);
                }
            }

            .guild:hover {
                .nodecor {
                    height: 28px;
                    opacity: 1;
                }

                .icon {
                    border-radius: 20px;
                }

                .tooltype {
                    position: absolute;
                    display: flex;
                    left: 104px;
                }
            }
        }

        .plugins {
            height: calc(100% - 88px - 24px);
            position: fixed;
            left: 92px;
            overflow: scroll;
            padding: 8px 8px 0px 4px;
            flex: 1 0 0;
            @include flex(column, flex-start, 0px, flex-start);
        }
    }
}

.open {
    animation: fadeInLeft .25s cubic-bezier(0.33, 1, 0.68, 1) 0s 1 normal none running;
    transform: translateX(0%);
    opacity: 1;
}

.hide {
    animation: fadeInRight .25s cubic-bezier(0.33, 1, 0.68, 1) 0s 1 normal none running;
    transform: translateX(-100%);
    opacity: 0;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 1;
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }

    to {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@keyframes opactiy {
    0% {
        opacity: 0;
        padding: 6px 20px;
    }

    50% {
        padding: 8px 24px;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 540px) {
    .sidebar {
        margin-top: 72px;
        height: calc((100% - 72px));
    }
}