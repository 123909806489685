@import '../../../../assets/scss/Mixins.scss';

.content {
    min-width: 306px;
    height: calc(100vh - 104px);
    margin: auto;
    @include flex(column, center, 4px, center);

    .title {
        @include text(32, 700, var(--text-color))
    }

    .description {
        @include flex(column, center, 8px, center);
        
        .text {
            @include text(18, 400, var(--text-color))
        }
    }

    .image {
        width: 400px;
        height: auto;
    }
}

@media screen and (max-width: 450px) {
    .content .image {
        width: 100%;
    }
}